import { Meta } from "@solidjs/meta";
import { Show } from "solid-js";

import styles from "~/components/CategoryHeader.module.scss";
import Title from "~/components/Title";

interface CategoryHeaderProps {
  readonly title: string;
  readonly cover: string;
  readonly coverSet: string;
  readonly body?: string;
}

export default function CategoryHeader(props: CategoryHeaderProps) {
  const title = () => `Artigos da coluna ${props.title}`;

  return (
    <>
      <Title>{title()}</Title>
      <Meta property="og:image" content={props.cover} />
      <div class={styles["category-header"]}>
        <div classList={{ [styles.image]: true, [styles.small]: !props.body }}>
          <img src={props.cover} srcSet={props.coverSet} sizes="auto" alt="" />
        </div>
        <div class={styles.meta}>
          <div class={styles.inner}>
            <div classList={{ [styles.image]: true, [styles.alternate]: true }}>
              <img
                src={props.cover}
                srcSet={props.coverSet}
                sizes="auto"
                alt=""
              />
            </div>
            <h1>
              Artigos da coluna <b>{props.title}</b>
            </h1>
          </div>
          <Show when={props.body}>
            <p>{props.body}</p>
          </Show>
        </div>
      </div>
    </>
  );
}
